<template>
  <div>
    <b-form-row v-if="restrictAddLinkAccountToMultipleCity">
      <b-col>
        <b-alert show variant="danger">{{ getMultipleCitiesSelectedError }}</b-alert>
      </b-col>
    </b-form-row>
    <template v-else>
      <LoaderIcon v-if="showLoader" />
      <TabsForm
        v-else
        formName="link-account"
        :formTabs="linkAccountFormTabs"
        :isEditPage="isEditPage"
        :formPayload="formPayload"
        :saveLoadingIcon="addEditLinkAccountLoading"
        @validateForm="validateLinkAccountForm"
        @submit="onSubmitLinkAccount"
      />
    </template>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { DISPLAY_MESSAGES } from '@/utilities/constants'
import { LINK_ACCOUNT_FORM_TABS } from '@/utilities/settings-constants'
import { useAxios } from '@/composables/useAxios'
import { useValidateFields } from '@/composables/useValidateFields'
import { ServiceUrls } from '@/utilities/serviceUrls'
import LoaderIcon from '@/assets/svg/loader.svg'
import cloneDeep from 'lodash/cloneDeep'
import { tenantSpecificURL } from '@/utilities/utilities'

export default {
  name: 'AddEditInternalLinkAccount',
  setup() {
    const { validateUrl, scrollToErrorMessage } = useValidateFields()
    const {
      isLoading: linkAccountDetailsLoading,
      response: linkAccountDetailsResponse,
      error: linkAccountDetailsError,
      callApi: linkAccountDetailsApi,
    } = useAxios()
    const {
      isLoading: addEditLinkAccountLoading,
      response: addEditLinkAccountResponse,
      error: addEditLinkAccountError,
      callApi: addEditLinkAccountApi,
    } = useAxios()
    const { response: linkAccountsResponse, callApi: linkAccountsApi } = useAxios()
    return {
      validateUrl,
      scrollToErrorMessage,
      linkAccountDetailsLoading,
      linkAccountDetailsResponse,
      linkAccountDetailsError,
      linkAccountDetailsApi,
      addEditLinkAccountLoading,
      addEditLinkAccountResponse,
      addEditLinkAccountError,
      addEditLinkAccountApi,
      linkAccountsResponse,
      linkAccountsApi,
    }
  },
  props: {
    mode: {
      default: 'page',
      type: String,
    },
  },
  data() {
    return {
      isEditPage: false,
      linkAccountFormTabs: cloneDeep(LINK_ACCOUNT_FORM_TABS),
      formPayload: {
        tenantId: null,
        accountName: null,
        authEndPoint: null,
        unLinkEndPoint: null,
        isForEachProperty: false,
        fields: [],
        applySecurityMethod: false,
        securityMethodId: null,
      },
    }
  },
  components: {
    LoaderIcon,
    TabsForm: () => import('@/components/common/TabsForm.vue'),
  },
  computed: {
    showLoader() {
      return this.isEditPage ? this.linkAccountDetailsLoading : false
    },
    restrictAddLinkAccountToMultipleCity() {
      return (this.selectedTenant.length > 1 || this.selectedTenant.some((tenant) => tenant.tenantID === 0)) && !this.isEditPage
    },
    getMultipleCitiesSelectedError() {
      return DISPLAY_MESSAGES.MULTIPLE_CITIES_ADD_LINK_ACCOUNT_ERROR
    },
    ...mapState({
      selectedTenant: (state) => state.common.selectedTenant,
      selectedLanguage: (state) => state.languages.selectedLanguage,
      defaultLanguage: (state) => state.languages.defaultLanguage,
    }),
  },
  created() {
    if (this.$route.name === 'Settings-edit-link-account') {
      this.isEditPage = true
      this.getLinkAccountDetails()
    }
    if (this.$route.name === 'Settings-add-link-account') this.resetSelectedLanguage()
  },
  methods: {
    showToaster(message, key, type) {
      this.$store.commit('common/setCustomToastData', {
        message: message,
        key: key,
        type: type,
      })
    },
    async getLinkAccountDetails() {
      if (this.selectedTenant.length && !this.restrictAddLinkAccountToMultipleCity) {
        await this.linkAccountDetailsApi({ method: 'get', url: `${ServiceUrls.getLinkAccountDetails}/${this.selectedLanguage}/${this.$route.params.id}` })
        if (this.linkAccountDetailsResponse) {
          this.formPayload = { ...this.linkAccountDetailsResponse, applySecurityMethod: !!this.linkAccountDetailsResponse.securityMethodId }
        }
        if (this.linkAccountDetailsError) {
          this.showToaster(false, 'FAILED', 'danger')
        }
      }
    },
    validateConfiguration() {
      const accountName = !!this.formPayload.accountName
      const fields = !!this.formPayload.fields.length
      const result = accountName && fields
      const index = this.linkAccountFormTabs.findIndex((tab) => tab.value === 'Configuration')
      this.linkAccountFormTabs[index].hasErrors = !result
      return result
    },
    validateApi() {
      const endPointUrl = this.formPayload.authEndPoint ? this.validateUrl(this.formPayload.authEndPoint) : false
      const unLinkEndPointUrl = this.formPayload.unLinkEndPoint ? this.validateUrl(this.formPayload.unLinkEndPoint) : false
      const securityMethod = this.formPayload.applySecurityMethod ? !!this.formPayload.securityMethodId : true
      const result = endPointUrl && unLinkEndPointUrl && securityMethod
      const index = this.linkAccountFormTabs.findIndex((tab) => tab.value === 'API')
      this.linkAccountFormTabs[index].hasErrors = !result
      return result
    },
    validateLinkAccountForm() {
      const configuration = this.validateConfiguration()
      const api = this.validateApi()
      return configuration && api
    },
    async onSubmitLinkAccount() {
      if (this.validateLinkAccountForm()) {
        if (!this.isEditPage) {
          this.formPayload.tenantId = Number(this.selectedTenant[0].tenantID)
        }
        const url = this.isEditPage ? ServiceUrls.updateLinkAccount : ServiceUrls.addLinkAccount
        await this.addEditLinkAccountApi({ method: 'post', url, data: this.formPayload })
        if (this.addEditLinkAccountResponse) {
          this.showToaster(false, this.isEditPage ? 'UPDATE_LINK_ACCOUNT' : 'ADD_LINK_ACCOUNT', 'success')
          if (this.mode === 'sidebar') {
            this.$emit('close')
            await this.linkAccountsApi({ method: 'get', url: tenantSpecificURL(ServiceUrls.getLinkAccounts) })
            if (this.linkAccountsResponse) {
              this.$store.commit('settings/setLinkAccounts', this.linkAccountsResponse)
            }
          } else {
            this.$router.push('/settings/settings-categories/link-accounts')
          }
        }
        if (this.addEditLinkAccountError) {
          if (this.addEditLinkAccountError?.errorMessage) {
            this.showToaster(this.addEditLinkAccountError?.errorMessage, false, 'danger')
          } else {
            this.showToaster(false, 'FAILED', 'danger')
          }
        }
      } else {
        this.showToaster(false, 'ADD_LINK_ACCOUNT_RESOLVE_ERRORS', 'danger')
      }
    },
    resetSelectedLanguage() {
      this.$store.commit('languages/setSelectedLanguage', this.defaultLanguage)
    },
  },
  watch: {
    formPayload: {
      deep: true,
      handler() {
        this.validateLinkAccountForm()
      },
    },
    selectedLanguage(newValue, oldValue) {
      if (this.isEditPage) this.getLinkAccountDetails()
    },
  },
}
</script>
